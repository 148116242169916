import axios from "../api/axios-admin-auth";

class GiftcardService {
    getGiftCardList() {
      return axios.get("/admin/gift-card-catalog");
    }
    createGiftCardOrder(data) {
      return axios.post("/admin/card-v2", data);
    }
}
  
export default new GiftcardService();