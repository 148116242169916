import axios from 'axios'
//import { Agent } from 'https'
const headers = { "Content-Type": "application/json" };
if (localStorage.getItem('gitfCardLoginToken')) {
    headers.Authorization = "bearer "+localStorage.getItem('gitfCardLoginToken');
  }
const instance = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  headers: headers,
  // baseURL: 'http://localhost:8080/v1/',
  //
  // // If the server has an invalid SSL cert
  // httpsAgent: new Agent({
  //   rejectUnauthorized: false
  // })
  //
  // // If the server doesn't handle CORS very well
  // headers: {
  //   'Access-Control-Allow-Origin': 'http://localhost:8080/',
  //   'Access-Control-Request-Method': '*',
  //   'Access-Control-Allow-Credentials': 'true',
  //   'Access-Control-Expose-Headers': 'Link'
  // }
})

export default instance
